<template>
  <div>
    <v-file-input
      prepend-icon="mdi-plus"
      hide-input
      @change="fileAdded"
      small-chips
      :label="text"
    ></v-file-input>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text style="color: white;">
          Procesando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["text"],
  data: () => {
    return {
      dialog: false
    };
  },
  methods: {
    generateUUID() {
      var d = new Date().getTime();
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0;
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = Math.random() * 16;
        if (d > 0) {
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    },
    toBuffer(ab) {
      var buf = new Buffer(ab.byteLength);
      var view = new Uint8Array(ab);
      for (var i = 0; i < buf.length; ++i) {
        buf[i] = view[i];
      }
      return buf;
    },
    fileAdded(file) {
      let self = this;
      const Minio = require("minio");

      const minioClient = new Minio.Client({
        endPoint: "s3.mylogy.xyz",
        port: 443,
        useSSL: true,
        accessKey: "ERB8F3Q7SRQIYY9BM9ND",
        secretKey: "PvETQB7EMME+u2ZCUgCPPJkMW9vCtpPrsCqSSTci"
      });

      let fileReader = new FileReader();
      const fileUUID = self.generateUUID();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = function(ev) {
        const result = self.toBuffer(ev.target.result);
        console.log(result);
        minioClient.putObject("temporal", `${fileUUID}.xlsx`, result, function(
          err,
          etag
        ) {
          if (err) {
            console.log(err);
          } else {
            self.dialog = true;
            fetch(process.env.VUE_APP_N8N_PROCESS, {
              mode: "no-cors",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
              },
              method: "post",
              body: JSON.stringify({ file_key: `${fileUUID}.xlsx` })
            })
              .then(res => {
                self.$alertify.success("Muestras importadas");
              })
              .catch(err => {
                self.$alertify.success("Ha ocurrido un error");
              })
              .then(() => {
                self.dialog = false;
                self.$emit("refresh");
              });
          }
        });
      };
    }
  }
};
</script>
